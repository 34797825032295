<template>
  <div class="basic-info">
    <ConfigurationHeaderContainer title="General Info for Project X"></ConfigurationHeaderContainer>
    {{ projectNumber }}
    <!-- <div style="display: flex; justify-content: space-between;">
      <div>
        <kendo-button class="k-button" :ref="'downloadConfiguration'" :disabled="downloadConfigurationDisabled" @click="downloadConfigurationTemplate">Download configuration template</kendo-button>
      </div>
      <div>
        <button class="k-button" @click="downloadConfigurationTemplate" disabled="disabled">Save</button>
        <button class="k-button" @click="importConfigurationTemplate">Cancel</button>
      </div>
    </div> -->
    <FormEntryContainer title="Project Number" type="text" size="10" :value="projectNumber"></FormEntryContainer>
    <FormEntryContainer title="Project Name" type="text" size="100"></FormEntryContainer>
    <FormEntryContainer title="Project Description" type="textarea" cols="200" rows="10"></FormEntryContainer>
    <FormEntryContainer
      title="Project Type"
      type="dropdown"
      :datasource="this.projectTypeDataSource"
      :valuefield="'ProjectTypeIdentity'"
      :textfield="'ProjectTypeName'" value="1">
    </FormEntryContainer>
    <FormEntryContainer
      title="Project Status"
      type="dropdown"
      :datasource="this.projectStatusDataSource"
      :valuefield="'ProjectStatusIdentity'"
      :textfield="'ProjectStatusName'" value="1">
    </FormEntryContainer>
    <FormEntryContainer title="Project Lead (PI)" type="text" size="50"></FormEntryContainer>
    <FormEntryContainer title="Project Client" type="text" size="50"></FormEntryContainer>
    <div class="form-entry-container">
      <div class="form-entry-label">Review Type (Previously Data Entry Type)</div>
      <div class="form-entry-input">
        <input type="radio" name="de-options" id="single" @click="changeDataEntry" :checked="!this.$store.state.isDoubleDataEntry">
        <label for="single">Single review</label>
        <input type="radio" name="de-options" id="single" @click="changeDataEntry" :checked="this.$store.state.isDoubleDataEntry">
        <label for="single">Double review</label>
      </div>
    </div>
    <div class="form-entry-container">
      <div class="form-entry-label">Allow review validation</div>
      <div class="form-entry-input">
        <input type="radio" name="allowvalidation" id="allowvalidation" :checked="true">
        <label for="single">Yes</label>
        <input type="radio" name="allowvalidation" id="allowvalidation">
        <label for="single">No</label>
    </div>
    <div class="form-entry-container">
      <div class="form-entry-label">Data Extraction Type</div>
      <div class="form-entry-input">
        <div><input type="radio" name="de-options2" id="extractionType" :checked="true">No extraction</div>
        <div><input type="radio" name="de-options2" id="extractionType">Single extraction</div>
        <div><input type="radio" name="de-options2" id="extractionType">Double extraction</div>
      </div>
    </div>

    </div>
    <FormEntryContainer title="Allow forms to finish later  (To be removed???)" name="yesNo3" type="radioYesNo" :datasource="this.yesNo"></FormEntryContainer>
    <FormEntryContainer title="Criteria for suspected duplicate (To be removed)" type="text"></FormEntryContainer>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import FormEntryContainer from '@/containers/FormEntryContainer'
import ProjectTypeDataSource from '@/assets/data/ProjectType.json'
import ProjectStatusDataSource from '@/assets/data/ProjectStatus.json'
import SearchStrategyDataSource from '@/assets/data/SearchStrategy.json'

export default {
  name: 'basic-info',
  props: ['ProjectNumber', 'ProjectDescription', 'ProjectType', 'ProjectStatus', 'ProjectLead', 'ProjectClient'],
  components: {
    ConfigurationHeaderContainer,
    FormEntryContainer
  },
  data () {
    return {
      projectNumber: this.ProjectNumber,
      downloadConfigurationDisabled: false,
      importConfigurationDisabled: true,
      projectTypeDataSource: ProjectTypeDataSource,
      projectStatusDataSource: ProjectStatusDataSource,
      searchStrategyDataSource: SearchStrategyDataSource
    }
  },
  methods: {
    changeDataEntry: function () {
      this.$store.state.isDoubleDataEntry = !this.$store.state.isDoubleDataEntry
    },
    changeActionVisualization: function () {
      this.$store.state.isFirstActionVisualization = !this.$store.state.isFirstActionVisualization
    }
  },
  mounted: function () {
  }
}
</script>
<style>
.container {
  display: table;
  padding-left: 0px;
}
.row {
  display: table-row;
}
.left, .right {
  display: table-cell;
}
.inner-column-container {
  display: flex;
  flex-direction: column;
}
</style>
